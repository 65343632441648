import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/home/home.vue')
  },
  
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/login.vue')
  }, 
  {
    path: '/reset',
    name: 'reset',
    component: () => import('../views/login/reset.vue')
  }, {
    path: '/changePwd',
    name: 'changePwd',
    component: () => import('../views/login/changePwd.vue')
  },{
    path: '/report',
    name: 'report',
    component: () => import('../views/report/report.vue')
  },
  {//商家店铺
    path: '/shopList',
    name: 'shopList',
    component: () => import('../views/shopList/shopList.vue')
  },
  {
    path: '/applicationShop',
    name: 'applicationShop',
    component: () => import('../views/applicationShop/applicationShop.vue')
  },
  {
    path: '/goodsList',
    name: 'goodsList',
    component: () => import('../views/goods/goodsList.vue')
  },
  {
    path: '/goodsDetail',
    name: 'goodsDetail',
    component: () => import('../views/goods/goodsDetail.vue')
  },
  
  //后台系统内的菜单都配置为index的子路由
  {
    path: '/personCenter',
    name: 'personCenter',
    component: () => import('../views/personCenter/personCenter.vue'),
    children: [
      //订单
      {
        path: '/myOrder',
        name: 'myOrder',
        component: () => import('../views/personCenter/myOrder.vue')
      },
      //收货地址
      {
        path: '/shippingSddress',
        name: 'shippingSddress',
        component: () => import('../views/personCenter/shippingSddress.vue')
      },
      {
        path: '/orderDetail',
        name: 'orderDetail',
        component: () => import('../views/personCenter/orderDetail.vue')
      },
      {
        path: '/follow',
        name: 'follow',
        component: () => import('../views/personCenter/follow.vue')
      },
      {
        path: '/eootprint',
        name: 'eootprint',
        component: () => import('../views/personCenter/eootprint.vue')
      },{ //发票管理
        path: '/bill',
        name: 'bill',
        component: () => import('../views/personCenter/bill.vue')
      },{ //发票管理
        path: '/billDetail',
        name: 'billDetail',
        component: () => import('../views/personCenter/billDetail.vue')
      },
      
    ]
  }
]

const router = new VueRouter({
  routes
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
