import user from "./user.js"
let indexApi = {};
let planting = {
  user
}

for(let item in planting){
  for(let obj in planting[item]){
    indexApi[obj] = planting[item][obj];
  }
}

export default {
  indexApi
}

// require("./iot.js")
// require("./material.js")
// require("./temporary.js")
// require("./user.js")