import {post ,get} from '../utils/request'
//通用字典接口
const querySysByDictClassify = p => post('/user/sysDict/querySysByDictClassify', p)

//上传文件
const upload = p => post('/commodity/fileUpload/upload', p , true)

//菜单接口
const getUserPermission = p => post('/user/getUserPermission', p)

//退出登录
const loginOut = p => post('/user/loginOut', p)

//登录接口
const loginIn = p => post('/user/login', p)

//修改密码
const modifyPassword = p => post('/user/modifyPassword', p)

//手机发送验证码
const sendSms = p => get('/user/sendSms', p)

//校验验证码接口
const queryCheckMobileCodeByCellPhone = p => post('/user/queryCheckMobileCodeByCellPhone', p)

//修改密码
const modifyForgetPasswordByPhone = p => post('/user/modifyForgetPasswordByPhone', p)

//申请开店
const createShop = p => post('/commodity/shop/createShop', p)

//查询单个店铺
const queryShop = p => post('/commodity/shop/queryShop', p)

//注册用户
const register = p => post('/user/register', p)

//订单查询
const queryUocOrderListForBuyers = p => post('/order/uocOrder/queryUocOrderListForBuyers', p)

// 查询1级分类
const queryUccGuideCatalogList = p => post('/commodity/guideCatalog/queryUccGuideCatalogList', p)

//查询三级类目
const queryUccGuideCatalogByList = p => post('/commodity/guideCatalog/queryUccGuideCatalogByList', p)

// 查询店铺列表
const queryShopList = p => post('/withOut/commodity/shop/queryShopList ', p)

//根据一级类目查询商品
const queryUccCommodityPageListByGuideCatalogId = p => post('/commodity/guideCatalog/queryUccCommodityPageListByGuideCatalogId', p)

//商品搜索接口
const queryCommodityPageList = p => post('/withOut/uccCommodity/withOutQueryCommodityPageList', p)

//商品搜索接口PC
const withOutqueryCommodityPageListByPC = p => post('/withOut/uccCommodity/withOutqueryCommodityPageListByPC', p)

//根据搜索查询商品
const querySpuSpecPropNameAndPropValuesByCommodityName = p => post('/withOut/uccCommodity/withOutQuerySpuSpecPropNameAndPropValuesByCommodityName', p)

const withOutQueryParameterAndValuesByCommodityNameForPC = p => post('/withOut/uccCommodity/withOutQueryParameterAndValuesByCommodityNameForPC', p)

//查询商品详情
const queryUccCommodityDetailsByApplet = p => post('/commodity/guideCatalog/queryUccCommodityDetailsByApplet', p)

//评价列表
const queryUocCommodityCommentPageList = p => post('/withOut/uocCommodityComment/queryUocCommodityCommentPageList', p)

//查询价格
const queryAttributeValueBySku = p => post('/commodity/guideCatalog/queryAttributeValueBySku', p)

//订单详情查询
const queryUocOrderInfoById = p => post('/order/uocOrder/queryUocOrderInfoById', p)

//查看物流信息
const queryExpressInfo = p => post('/order/uocExpress/queryExpressInfo', p)

// 订单开票详情
const queryByInvoiceOrderRel = p => post('/order/invoiceOrderRel/queryByInvoiceOrderRel', p)

// 地址管理
const queryShippingAddressList = p => post('/commodity/shippingAddress/queryShippingAddressList', p)

// 查询商品关注列表
const queryCommodityFavoritesPageList = p => post('/commodity/commodityFavorites/queryCommodityFavoritesPageList', p)

// 记录足迹
const createFootprint = p => post('/commodity/footprint/createFootprint', p)

// 足迹列表
const queryFootprintListByKeyDate = p => post('/commodity/footprint/queryFootprintListByKeyDate', p)

// 发票部分查询订单详情
const queryUocOrderInfoForBuyer = p => post('/order/uocOrder/queryUocOrderInfoForBuyer', p)

// 判断此商品是否关注
const queryByCommodityFavoritesByUserIdAndCommodityId = p => post('/commodity/commodityFavorites/queryByCommodityFavoritesByUserIdAndCommodityId', p)

// 设置商品关注
const createCommodityFavorites = p => post('/commodity/commodityFavorites/createCommodityFavorites', p)

// 取消商品关注
const batchCancelCommodityFavoritesByCommdityIds = p => post('/commodity/commodityFavorites/batchCancelCommodityFavoritesByCommdityIds', p)

//被驳回后重新申请店铺
const logicDeleteShop = p => post('/commodity/shop/logicDeleteShop', p)

export default {
    querySysByDictClassify,
    getUserPermission,
    loginIn,
    upload,
    loginOut,
    modifyPassword,
    sendSms,
    queryCheckMobileCodeByCellPhone,
    modifyForgetPasswordByPhone,
    createShop,
    queryShop,
    register,
    queryUocOrderListForBuyers,
    queryUccGuideCatalogByList,
    queryUccCommodityPageListByGuideCatalogId,
    queryCommodityPageList,
    querySpuSpecPropNameAndPropValuesByCommodityName,
    queryUccCommodityDetailsByApplet,
    queryUocCommodityCommentPageList,
    queryAttributeValueBySku,
    queryUocOrderInfoById,
    queryExpressInfo,
    queryByInvoiceOrderRel,
    queryShippingAddressList,
    queryCommodityFavoritesPageList,
    createFootprint,
    queryFootprintListByKeyDate,
    queryUocOrderInfoForBuyer,
    queryByCommodityFavoritesByUserIdAndCommodityId,
    createCommodityFavorites,
    batchCancelCommodityFavoritesByCommdityIds,
    queryUccGuideCatalogList,
    queryShopList,
    logicDeleteShop,
    withOutqueryCommodityPageListByPC,
    withOutQueryParameterAndValuesByCommodityNameForPC
  }